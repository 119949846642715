var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as Sentry from '@sentry/browser';
import { ControllerType, EventType } from './types';
import { addSearchBox, isSearchBox, getAllSearchBoxes, initSearchBoxSuggestions, } from './searchBox';
import { addSearchResultsHeader, getSearchResultsComponent, getSearchResultsPage, updateSearchResultsPageData, } from './searchResults';
import { addSearchAppController, connectControllerWithSearchBox, connectControllerWithSearchResults, getSearchAppControllers, isComponentConnected, connectSearchBox, } from './searchAppController';
import { removeController, removeControllerConnectedComponents, getControllerConnectedComponents, } from '@wix/platform-editor-helpers';
import { create as createFedopsLogger } from '@wix/fedops-logger';
import { SENTRY_DSN, setSentryContext, withErrorReporting } from './sentry';
import { createAppApi } from './appApi';
var editor;
// let experiments: Experiments;
// TODO: review when https://github.com/wix/yoshi/pull/1496 resolved
var ARTIFACT_VERSION = window.__CI_APP_VERSION__;
var fedopsLogger = createFedopsLogger('search-editor-platform');
var Interactions;
(function (Interactions) {
    Interactions["EditorReady"] = "EditorReady";
    Interactions["InstallApp"] = "InstallApp";
    Interactions["RemoveApp"] = "RemoveApp";
    Interactions["SearchBoxAdded"] = "SearchBoxAdded";
})(Interactions || (Interactions = {}));
Sentry.init({
    dsn: SENTRY_DSN,
    release: ARTIFACT_VERSION,
    enabled: process.env.NODE_ENV === 'production',
    // NOTE: We don't need to report all `window.onerror` events. Manual capturing  is enough
    // https://docs.sentry.io/platforms/javascript/#automatically-capturing-errors-with-promises
    integrations: [
        new Sentry.Integrations.GlobalHandlers({
            onunhandledrejection: false,
            onerror: false,
        }),
    ],
});
function isSuggestionExperimentEnabled() {
    return true;
}
function onSearchBoxAdded(componentRef) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, isComponentConnected(editor, componentRef)];
                case 1:
                    if (!!(_a.sent())) return [3 /*break*/, 3];
                    return [4 /*yield*/, connectSearchBox(editor, componentRef)];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3:
                    if (!isSuggestionExperimentEnabled()) return [3 /*break*/, 5];
                    return [4 /*yield*/, initSearchBoxSuggestions(editor, componentRef)];
                case 4:
                    _a.sent();
                    _a.label = 5;
                case 5: return [2 /*return*/];
            }
        });
    });
}
function doFirstInstall() {
    return __awaiter(this, void 0, void 0, function () {
        var searchBoxRef, _a, searchResultsRef, searchResultsPageRef, searchAppControllerRef;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, addSearchBox(editor)];
                case 1:
                    searchBoxRef = _b.sent();
                    return [4 /*yield*/, Promise.all([getSearchResultsComponent(editor), getSearchResultsPage(editor)])];
                case 2:
                    _a = _b.sent(), searchResultsRef = _a[0], searchResultsPageRef = _a[1];
                    if (!searchResultsRef) return [3 /*break*/, 4];
                    return [4 /*yield*/, addSearchResultsHeader(editor, {
                            searchResultsPageRef: searchResultsPageRef,
                            searchResultsRef: searchResultsRef,
                        })];
                case 3:
                    _b.sent();
                    _b.label = 4;
                case 4: return [4 /*yield*/, updateSearchResultsPageData(editor, {
                        searchResultsPageRef: searchResultsPageRef,
                    })];
                case 5:
                    _b.sent();
                    return [4 /*yield*/, addSearchAppController(editor)];
                case 6:
                    searchAppControllerRef = _b.sent();
                    return [4 /*yield*/, Promise.all([
                            connectControllerWithSearchBox(editor, searchAppControllerRef, searchBoxRef),
                            searchResultsRef &&
                                connectControllerWithSearchResults(editor, searchAppControllerRef, searchResultsRef),
                        ])];
                case 7:
                    _b.sent();
                    return [4 /*yield*/, editor.SDK.pages.navigateTo(editor.token, {
                            pageRef: searchResultsPageRef,
                        })];
                case 8:
                    _b.sent();
                    return [4 /*yield*/, editor.SDK.selection.selectComponentByCompRef(editor.token, {
                            compsToSelect: [searchBoxRef],
                        })];
                case 9:
                    _b.sent();
                    return [2 /*return*/];
            }
        });
    });
}
function getConnectedSearchBoxes() {
    return __awaiter(this, void 0, void 0, function () {
        var controllerRefs, connectedSearchBoxes, _i, controllerRefs_1, controllerRef, connectedComponentsRefs, _a, connectedComponentsRefs_1, componentRef;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, getSearchAppControllers(editor)];
                case 1:
                    controllerRefs = _b.sent();
                    connectedSearchBoxes = [];
                    _i = 0, controllerRefs_1 = controllerRefs;
                    _b.label = 2;
                case 2:
                    if (!(_i < controllerRefs_1.length)) return [3 /*break*/, 8];
                    controllerRef = controllerRefs_1[_i];
                    return [4 /*yield*/, getControllerConnectedComponents(editor, controllerRef)];
                case 3:
                    connectedComponentsRefs = _b.sent();
                    _a = 0, connectedComponentsRefs_1 = connectedComponentsRefs;
                    _b.label = 4;
                case 4:
                    if (!(_a < connectedComponentsRefs_1.length)) return [3 /*break*/, 7];
                    componentRef = connectedComponentsRefs_1[_a];
                    return [4 /*yield*/, isSearchBox(editor, componentRef)];
                case 5:
                    if (_b.sent()) {
                        connectedSearchBoxes.push(componentRef);
                    }
                    _b.label = 6;
                case 6:
                    _a++;
                    return [3 /*break*/, 4];
                case 7:
                    _i++;
                    return [3 /*break*/, 2];
                case 8: return [2 /*return*/, connectedSearchBoxes];
            }
        });
    });
}
function patchInputFontProperty(componentRef) {
    return __awaiter(this, void 0, void 0, function () {
        var old, needsInputFont;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, editor.SDK.components.style.get(editor.token, {
                        componentRef: componentRef,
                    })];
                case 1:
                    old = _a.sent();
                    needsInputFont = old && old.style && old.style.properties && !old.style.properties.inputFont;
                    if (!needsInputFont) {
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, editor.SDK.components.style.updateFull(editor.token, {
                            componentRef: componentRef,
                            style: __assign(__assign({}, old), { style: __assign(__assign({}, old.style), { properties: __assign(__assign({}, old.style.properties), { inputFont: 'font_8' }), propertiesSource: __assign(__assign({}, old.style.propertiesSource), { inputFont: 'value' }) }) }),
                        })];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
// tslint:disable-next-line:no-shadowed-variable
export var editorReady = withErrorReporting(function editorReady(SDK, appDefinitionId, payload) {
    return __awaiter(this, void 0, void 0, function () {
        var connectedSearchBoxes, allSearchBoxes, unconnectedSearchBoxes;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    fedopsLogger.interactionStarted(Interactions.EditorReady);
                    setSentryContext(payload);
                    editor = {
                        SDK: SDK,
                        appDefinitionId: appDefinitionId,
                        // NOTE: https://wix.slack.com/archives/C4KPAQ33K/p1564490148055400
                        token: appDefinitionId,
                    };
                    SDK.editor.setAppAPI('', createAppApi());
                    if (!payload.firstInstall) return [3 /*break*/, 2];
                    fedopsLogger.interactionStarted(Interactions.InstallApp);
                    return [4 /*yield*/, doFirstInstall()];
                case 1:
                    _a.sent();
                    fedopsLogger.interactionEnded(Interactions.InstallApp);
                    _a.label = 2;
                case 2: return [4 /*yield*/, getConnectedSearchBoxes()];
                case 3:
                    connectedSearchBoxes = _a.sent();
                    return [4 /*yield*/, getAllSearchBoxes(editor)];
                case 4:
                    allSearchBoxes = _a.sent();
                    if (!(allSearchBoxes.length !== connectedSearchBoxes.length)) return [3 /*break*/, 6];
                    Sentry.withScope(function (scope) {
                        scope.setTags({
                            searchBoxesCount: "" + allSearchBoxes.length,
                            searchBoxesConnectedCount: "" + connectedSearchBoxes.length,
                            searchBoxesUnconnectedCount: "" + (allSearchBoxes.length -
                                connectedSearchBoxes.length),
                        });
                        Sentry.captureMessage("There are more search boxes than connected search boxes");
                    });
                    unconnectedSearchBoxes = allSearchBoxes.filter(function (componentRef) { return !connectedSearchBoxes.includes(componentRef); });
                    return [4 /*yield*/, Promise.all(unconnectedSearchBoxes.map(function (componentRef) {
                            return connectSearchBox(editor, componentRef);
                        }))];
                case 5:
                    _a.sent();
                    connectedSearchBoxes.push.apply(connectedSearchBoxes, unconnectedSearchBoxes);
                    _a.label = 6;
                case 6: return [4 /*yield*/, Promise.all(allSearchBoxes.map(patchInputFontProperty))];
                case 7:
                    _a.sent();
                    if (!isSuggestionExperimentEnabled()) return [3 /*break*/, 9];
                    return [4 /*yield*/, Promise.all(connectedSearchBoxes.map(function (connectedSearchBoxRef) {
                            return initSearchBoxSuggestions(editor, connectedSearchBoxRef);
                        }))];
                case 8:
                    _a.sent();
                    _a.label = 9;
                case 9: return [4 /*yield*/, editor.SDK.document.application.registerToCustomEvents(editor.token, {
                        eventTypes: [EventType.ComponentAddedToStage],
                    })];
                case 10:
                    _a.sent();
                    fedopsLogger.interactionEnded(Interactions.EditorReady);
                    return [2 /*return*/];
            }
        });
    });
});
function onComponentAddedToStage(payload) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: 
                // NOTE: uncomment this code when problem with controllers will be resolved
                // payload.appDefinitionId === editor.appDefinitionId &&
                return [4 /*yield*/, isSearchBox(editor, payload.compRef)];
                case 1:
                    if (!
                    // NOTE: uncomment this code when problem with controllers will be resolved
                    // payload.appDefinitionId === editor.appDefinitionId &&
                    _a.sent()) 
                    // NOTE: uncomment this code when problem with controllers will be resolved
                    // payload.appDefinitionId === editor.appDefinitionId &&
                    return [3 /*break*/, 3];
                    fedopsLogger.interactionStarted(Interactions.SearchBoxAdded);
                    return [4 /*yield*/, onSearchBoxAdded(payload.compRef)];
                case 2:
                    _a.sent();
                    fedopsLogger.interactionEnded(Interactions.SearchBoxAdded);
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    });
}
// tslint:disable-next-line:no-shadowed-variable
export var onEvent = withErrorReporting(function onEvent(_a) {
    var eventType = _a.eventType, eventPayload = _a.eventPayload;
    return __awaiter(this, void 0, void 0, function () {
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _b = eventType;
                    switch (_b) {
                        case EventType.ComponentAddedToStage: return [3 /*break*/, 1];
                    }
                    return [3 /*break*/, 3];
                case 1: return [4 /*yield*/, onComponentAddedToStage(eventPayload)];
                case 2:
                    _c.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    });
});
// tslint:disable-next-line:no-shadowed-variable
export var handleAction = withErrorReporting(function handleAction(_a) {
    var type = _a.type;
    return __awaiter(this, void 0, void 0, function () {
        var controllerRefs, _i, controllerRefs_2, controllerRef;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!(type === 'removeApp')) return [3 /*break*/, 8];
                    fedopsLogger.interactionStarted(Interactions.RemoveApp);
                    return [4 /*yield*/, getSearchAppControllers(editor)];
                case 1:
                    controllerRefs = _b.sent();
                    _i = 0, controllerRefs_2 = controllerRefs;
                    _b.label = 2;
                case 2:
                    if (!(_i < controllerRefs_2.length)) return [3 /*break*/, 6];
                    controllerRef = controllerRefs_2[_i];
                    return [4 /*yield*/, removeControllerConnectedComponents(editor, controllerRef)];
                case 3:
                    _b.sent();
                    return [4 /*yield*/, removeController(editor, controllerRef)];
                case 4:
                    _b.sent();
                    _b.label = 5;
                case 5:
                    _i++;
                    return [3 /*break*/, 2];
                case 6: return [4 /*yield*/, editor.SDK.editor.save()];
                case 7:
                    _b.sent();
                    fedopsLogger.interactionEnded(Interactions.RemoveApp);
                    _b.label = 8;
                case 8: return [2 /*return*/];
            }
        });
    });
});
export var getAppManifest = function () {
    var _a;
    return Promise.resolve({
        controllersStageData: (_a = {},
            _a[ControllerType.SearchApp] = {
                default: {
                    visibility: 'NEVER',
                },
            },
            _a),
    });
};
